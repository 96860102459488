export default function counter() {
  const system = document.querySelector(".system");
  const numDigit = document.querySelector(".system-number-digit");
  const numDecimal = document.querySelector(".system-number-float");
  let onlyOnce = false;

  function animateValue(obj, start, end, duration, isWholeNum) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = isWholeNum
        ? Math.floor(progress * (end - start) + start)
        : Math.floor(progress * (end - start) + start) % 10;
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  window.addEventListener("scroll", () => {
    let winScrollY = window.scrollY;

    if (winScrollY >= system.offsetTop) {
      system.classList.add("is-counting");
      if (!onlyOnce) {
        setTimeout(() => {
          animateValue(numDigit, 0, 98, 1500, true);
          animateValue(numDecimal, 0, 98, 1500, false);
        }, 300);

        onlyOnce = true;
      }
    }
  });
}
