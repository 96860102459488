import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default function carousel() {
  const cards = document.querySelector(".js-comments-cards");

  if (cards) {
    new Swiper(cards, {
      // speed: 500,
      slidesPerView: 1,
      width: 256,
      centeredSlides: false,
      spaceBetween: 25,
      scrollbar: {
        draggable: true,
      },
      watchSlidesProgress: true,
      on: {
        slideChange: (swiper) => {
          // console.log(swiper)
        },
      },
      breakpoints: {
        // when window width is >= 375
        768: {
          spaceBetween: 25,
          slidesPerView: 3,
          width: null,
        },
        // when window width is >= 375
        470: {
          spaceBetween: 25,
          slidesPerView: 2,
          width: null,
        },
      },
      // loop: true,
      // autoplay: {
      //   delay: autoPlayDelay,
      //   disableOnInteraction: false,
      // },
      navigation: {
        nextEl: ".next",
        prevEl: ".prev",
      },
    });
  }
}
