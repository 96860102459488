export default function lazyload() {
  const IS_LOADED = 'is-loaded';
  let observer = new IntersectionObserver(entries => {
  
  entries.forEach(e => {
    if (e.isIntersecting && !e.target.classList.contains(IS_LOADED)) {
    let el = e.target;
    
    if (el.dataset.src && (el.nodeName.toLowerCase() === 'img')) {
      el.setAttribute('src', el.dataset.src);
    } else {
      el.style.backgroundImage = "url("+el.dataset.src+")"
    }
    
      e.target.classList.add(IS_LOADED);
    }
  });
  }, {
    rootMargin: (window.innerWidth <= 767) ? `${window.innerHeight * 2}px 0px ${window.innerHeight * 2}px 0px` : '0px 0px 0px 0px'
  });
  
  const lazyload = document.querySelectorAll('.lazyload');
  lazyload.forEach(img => {
    observer.observe(img);
  });
}