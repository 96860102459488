export default function scrollTrigger() {
  const section = document.querySelector(".smiles");
  const cta = document.querySelector(".cta-mobile");
  const header = document.querySelector(".header");

  window.addEventListener("scroll", () => {
    let winScrollY = window.scrollY;

    if (winScrollY > section.offsetTop) {
      cta.classList.add("is-visible");
    } else {
      cta.classList.remove("is-visible");
    }

    if (winScrollY > header.clientHeight) {
      header.classList.add("is-shrink");
    } else {
      header.classList.remove("is-shrink");
    }
  });
}
