if (!NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

import lazyload from "./components/lazyload";
import FadeIn from "./components/fadein";
import modal from "./components/modal";
import scrollTrigger from "./components/scrollTrigger";
import carousel from "./components/carousel";
import preload from "./components/preload";
import counter from "./components/counter";

lazyload();
FadeIn();
modal();
scrollTrigger();
carousel();
preload();
counter();
