export default function FadeIn() {
  let animteIn = document.querySelectorAll('.animate-in');

  if (animteIn.length) {
    let animteInSettings = {
      rootMargin: '0px',
      threshold: [0.2],
    };
    let animateInObserver = new IntersectionObserver(
      sectionCallBack,
      animteInSettings
    );

    animteIn.forEach((animateIn) => {
      animateInObserver.observe(animateIn);
    });

    function sectionCallBack(sections) {
      sections.forEach((section) => {
        if (section.isIntersecting) {
          section.target.classList.add('is-shown');
        }
      });
    }
  }
}
