export default function modal() {
  const cards = document.querySelectorAll(".card-comment");
  const modals = document.querySelectorAll(".modal");
  const close = document.querySelectorAll(".js-modal-close");

  cards.forEach((el, index) => {
    el.addEventListener("click", () => {
      modals.forEach((elem) => {
        elem.classList.remove("is-visible");
      });
      modals[index].classList.add("is-visible");
    });
  });

  close.forEach((el, index) => {
    el.addEventListener("click", () => {
      modals.forEach((elem) => {
        elem.classList.remove("is-visible");
      });
    });
  });
}
