import { gsap } from "gsap/all";

export default function preload() {
  const body = document.querySelector("body");

  window.addEventListener("load", (e) => {
    window.scrollTo(0, 0);
  });

  const onStart = () => {
    body.classList.add("is-preloading");
  };

  const onFinish = () => {
    body.classList.remove("is-preloading");
  };

  const preload = () => {
    const tl = gsap.timeline();

    tl.set(".preload", { className: "preload is-hidden", delay: 0.5 })
      .set(".mv-content", { className: "mv-content is-visible" }, "+=0.5")
      .set(
        ".mv-model-picture",
        { className: "mv-model-picture is-visible" },
        "+=0.35"
      );

    // tl.fromTo(
    //   ".preload",
    //   { yPercent: 0 },
    //   { yPercent: -100, duration: 0.3, ease: "power1.out", delay: 0.6 },
    //   0
    // )
    //   .fromTo(
    //     ".mv-content",
    //     { alpha: 0, y: 50 },
    //     { alpha: 1, y: 0, duration: 0.4, ease: "power1.out" },
    //     "+=0.3"
    //   )
    //   .fromTo(
    //     ".mv-model",
    //     { alpha: 0, x: -50 },
    //     { alpha: 1, x: 0, duration: 0.4, ease: "power1.out" },
    //     "+=0.1"
    //   );

    return tl;
  };

  let loaderTl = gsap.timeline({
    onStart: onStart,
    onComplete: onFinish,
  });
  loaderTl.add(preload());
}
